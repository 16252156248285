/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import { brandToLogo } from "layouts/Logo";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
// import vehiclesTable from "./data/chargingTable";
import SoftInput from "components/SoftInput";
import React from "react";
import SoftBadge from "components/SoftBadge";
import SoftButton from "components/SoftButton";
import { Divider } from "@mui/material";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import Grid from "@mui/material/Grid";
import KiaEV from "assets/images/cars/kia_soul.png";
import teslaEV from "assets/images/cars/tesla_model_3.png";
import fordEV from "assets/images/cars/ford_150_lightning.png";
import { vehicleStatusTableColumns } from "./data/chargingTable";
import ProgressBar from "./data/progess-bar.component";
import SoftAvatar from "components/SoftAvatar";
import { BACKEND_URL } from "configs";
import { position } from "stylis";
import { PieChart } from "react-minimal-pie-chart";
import { CgProfile } from "react-icons/cg";
import { ImSwitch } from "react-icons/im";
import { FiArrowRight } from "react-icons/fi";
import { MdOutlineGarage } from "react-icons/md";
import { ToggleSlider } from "react-toggle-slider";

function OptefyDemo() {
  const [vehicles, setVehicles] = React.useState([
    { value: "kia1", label: "Kia GVDC701" },
    { value: "kia2", label: "Kia GVDC702" },
  ]);

  const [vehicleOptions, setVehicleOptions] = React.useState([
    { value: "all", label: "All" },
    { value: "kia1", label: "Kia GVDC701" },
    { value: "kia2", label: "Kia GVDC702" },
  ]);

  let currentMonth = new Intl.DateTimeFormat("en-US", { month: "long" }).format(new Date());
  let currentYear = new Intl.DateTimeFormat("default", { year: "numeric" }).format(new Date());

  const [vehicleMap, setVehicleMap] = React.useState({}); // maps each vehicle id to its data
  const [selectedVehicle, setSelectedVehicle] = React.useState({});
  const [vehicleLog, setVehicleLog] = React.useState({ value: "all", label: "All" });
  const [vehicleConnected,setVehicleConnected ] = React.useState({});
  
  // const [chargeRows, setChargeRows] = React.useState([]);
  // const [displayedRows, setDisplayedRows] = React.useState([]);
  const vehicleStatusColumns = vehicleStatusTableColumns;
  const [vehicleStatusRows, setVehicleStatusRows] = React.useState([]); // contains all rows
  const [vehicleStatusDisplayedRows, setVehicleStatusDisplayedRows] = React.useState([]); // rows to be displayed in the table
  const [savingsRow, setSavingsRow] = React.useState(["N/A", "N/A", "N/A", "N/A"]);
  const [badgeRow, setBadgeRow] = React.useState(["N/A", "N/A", "N/A", "N/A"]);
  const defaultLabelStyle = {
    fontSize: "10px",
    fontFamily: "sans-serif",
    fill: "black",
    fontWeight: "bold",
  };
  const iconStyle = {
    fontSize: "22px",
    color: "#A0B0D0",
    verticalAlign: "middle",
  };
  function Author(image, name, email) {
    return (
      <SoftBox display="flex" alignItems="flex-start" pl={9} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {name}
          </SoftTypography>
          <SoftTypography variant="caption" color="secondary">
            {email}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    );
  }
  

  function getChargingStatus(status) {
    if (status.Charging) {
      return (
        <SoftBadge
          style={{ zIndex: 0, position: "relative" }}
          variant="contained"
          color="success"
          badgeContent={<span style={{ minWidth: "72.9px" }}>CHARGING</span>}
          container
        />
      );
    } else if (status.Plugged) {
      return (
        <SoftBadge
          style={{ zIndex: 0, position: "relative" }}
          variant="contained"
          color="blue"
          badgeContent={<span style={{ minWidth: "72.9px" }}>PLUGGED</span>}
          container
        />
      );
    } else {
      return (
        <SoftBadge
          style={{ zIndex: 0, position: "relative" }}
          variant="contained"
          color="error"
          badgeContent={"unplugged"}
          container
        />
      );
    }
  }

  function makeDateReadable(unixDate, one_line) {
    const milliseconds = unixDate * 1000;
    const dateObject = new Date(milliseconds);

    const humanDateFormat = dateObject.toLocaleString();

    let weekday = dateObject.toLocaleString("en-US", { weekday: "long" }); // Monday
    let month = dateObject.toLocaleString("en-US", { month: "long" }); // December
    let date = dateObject.toLocaleString("en-US", { day: "numeric" }); // 9
    let year = dateObject.toLocaleString("en-US", { year: "numeric" }); // 2019
    let time = new Date(unixDate * 1000).toLocaleTimeString("en-US");

    if (one_line) {
      return month + " " + date + ", " + year + ", " + time;
    } else {
      return month + " " + date + ", " + year, time;
    }
  }

  function VehicleImage(vehicle) {
    let img = brandToLogo(vehicle.make);
    const captionStyle = {
      "position": 'absolute',
      "bottom": 0,
      "left": 0,
      "right": 0,
      "marginBottom": '10px',
      "textAlign": "center",
    }
    return (
      <div style={{ position: "relative", "marginRight": "30px", width: "20%" }}>
        <img src={img} style={{ maxWidth: "200px", maxHeight: "50%", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", flex: "1" }} />
        <SoftTypography variant="body1" style={captionStyle}>
          {selectedVehicle.make} {selectedVehicle.model}
        </SoftTypography>
      </div>
    );
  }

  React.useEffect(() => {
    async function fetchData() {
      const access_token = localStorage.getItem("access_token");
      let res = await fetch(BACKEND_URL + "/summary/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + access_token,
        },
      });
      
      if (res.status == 200) {
        let response_json = await res.json();
        let new_mapping = [
          response_json[0].cost_savings + "%",
          response_json[0].emissions_savings + "%",
          response_json[0].battery_efficiency + "%",
          "50-80%",
        ];

        let new_badges = [
          "+9.3% This Month",
          "+4.5% This Month",
          "+8.2% This Month",
          "Same as Last Month",
        ];
        setSavingsRow(new_mapping);
        setBadgeRow(new_badges);
      }
    }
    fetchData();
  }, []);
  React.useEffect(() => {
        async function fetchData() {
            const access_token = localStorage.getItem("access_token");
            let res = await fetch(BACKEND_URL + "/vehicles/connected/", {
                method: "GET",
                headers: {
                    Authorization: "JWT " + access_token,
                },
            });

            if (res.status == 200) {
                let response_json = await res.json();
                setVehicleConnected({...response_json})
                
            }
        }
        fetchData();
    }, []);


  const handleToggle = async (e, state) => {
    await updateOptefy(e.id);
  };

  async function updateOptefy(id) {
    const access_token = localStorage.getItem("access_token");

    let res = await fetch(BACKEND_URL + "/vehicles/updateOptefy/" + id + "/", {
      method: "POST",
      headers: {
        Authorization: "JWT " + access_token,
      },
    });
    if (res.status == 200) {
      const { scrollX, scrollY } = window;
      window.location.reload();
    }
  }

  React.useEffect(() => {
    async function fetchData() {
      const access_token = localStorage.getItem("access_token");
      let new_vehicle_map = {};
      let res = await fetch(BACKEND_URL + "/vehicles/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + access_token,
          },

      });
      
      if (res.status == 200) {
        let response_json = await res.json();
        let new_rows = response_json.map((e) => {
          return { ...e, value: e.vin, label: e.make + " " + e.alias };
        });
        
        new_rows.forEach((element) => {
          new_vehicle_map[element.id] = element;
        });
        setVehicleMap(new_vehicle_map);

        let vehicle_status_rows = response_json.map((e) => {
          let logoImage = brandToLogo(e.make);
          return {
            vin: e.vin,
            alias: (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {e.alias}
              </SoftTypography>
            ),
            vehicle: Author(
              logoImage,
              e.make,
              e.alias
            ),
            "license plate": (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {e.license_plate}
              </SoftTypography>
            ),
            "current status": getChargingStatus(e.status),
            "scheduled charge": (
              <SoftTypography style={{ textAlign: "left" }} variant="caption">
                10:00 PM
              </SoftTypography>
            ),
            battery: (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                <ProgressBar batteryPercentage={e.status.Battery} vehicle={e} />
              </SoftTypography>
            ),
            optefied: (
              <div>
                <ToggleSlider
                  active={e.optefied}
                  onToggle={(state) => {
                    const confirmBox = window.confirm("Are you sure you want to make this vehicle optefied/not optefied ")
                  if (confirmBox === true) {
                    handleToggle(e, state)
                  }
                  else {
                    window.location.reload();
                  }
                }}
                />
              </div>
            ),
          };
        });

        let new_chargelog_vehicles = [{ value: "All", label: "All" }].concat(new_rows);
        setVehicleOptions(new_chargelog_vehicles);
        setVehicles(new_rows);
        setSelectedVehicle(new_rows[0]);
        setVehicleStatusDisplayedRows(vehicle_status_rows);
        setVehicleStatusRows(vehicle_status_rows);
      }
    }
    fetchData();
  }, []);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <div style={{ display: "flex", alignItems: "flex-end", justifyItems: "center" }}>
            <SoftTypography variant="h2" style={{ marginBottom: "30px", marginRight: "10px" }}>
              Optefy Summary{" "}
            </SoftTypography>
            <SoftTypography variant="h6" style={{ marginBottom: "30px" }}>
              {currentMonth} {currentYear}
            </SoftTypography>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3} style={{ display: "flex", alignItems: "stretch" }}>
              <Card>
                <SoftBox p={3}>
                  <SoftBadge
                    variant="standard"
                    color="success"
                    badgeContent={"Fleet Manager Summary"}
                    container
                    size="lg"
                    style={{
                      backgroundColor: "#CDF59B",
                      borderRadius: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                  <SoftTypography
                    variant="body2"
                    fontWeight="bold"
                    style={{
                      paddingTop: "2em",
                      paddingBottom: "1.4em",
                      fontSize: "18px",
                      marginLeft: "0.5em",
                    }}
                  >
                    <CgProfile style={{ ...iconStyle }} /> Fleet Manager{" "}
                    <FiArrowRight style={{ ...iconStyle }} /> Ron Harper
                    <br /> <br />
                    <ImSwitch style={{ ...iconStyle }} /> Optefy Mode{" "}
                    <FiArrowRight style={{ ...iconStyle }} /> Auto <br /> <br />
                    <MdOutlineGarage style={{ ...iconStyle }} /> Parking Lot{" "}
                    <FiArrowRight style={{ ...iconStyle }} /> Univ Drive
                  </SoftTypography>
                </SoftBox>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} xl={3} style={{ display: "flex", alignItems: "stretch" }}>
              <Card>
                <SoftBox p={3}>
                  <SoftBadge
                    variant="standard"
                    badgeContent={"Functioning Chargers"}
                    container
                    size="lg"
                    style={{
                      backgroundColor: "#CDF59B",
                      borderRadius: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                  <PieChart
                    style={{ width: "50%", height: "80%", marginLeft: "25%", paddingTop: "1em" }}
                    center={[20, 20]}
                    viewBoxSize={[40, 40]}
                    radius={20}
                    label={({ dataEntry }) =>
                      dataEntry.title == "One" ? dataEntry.value + "%" : ""
                    }
                    labelPosition={0}
                    labelStyle={{
                      ...defaultLabelStyle,
                    }}
                    lineWidth={20}
                    data={[
                      { title: "One", value: 96, color: "#CDF59B" },
                      { title: "Two", value: 4, color: "#FC9797" },
                    ]}
                  />
                  <br />
                  {selectedVehicle && Object.keys(selectedVehicle).length !== 0 && (
                    <SoftTypography
                      variant="caption"
                      fontWeight="regular"
                      style={{ marginLeft: "20%" }}
                    >
                      Last Updated{" "}
                      {(() => {
                        const lastUpdatedStr =
                          vehicleMap[selectedVehicle.id].status["Last updated"];
                        const dateObject = new Date(lastUpdatedStr * 1000);
                        const now = new Date();
                        const diffMs = now - dateObject;
                        const diffMins = Math.floor(diffMs / 1000 / 60);
                        return diffMins + " minutes ago";
                      })()}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} xl={3} style={{ display: "flex", alignItems: "stretch" }}>
              <Card>
                <SoftBox p={3}>
                  <SoftBadge
                    variant="standard"
                    color="success"
                    badgeContent={"Vehicles Connected"}
                    container
                    size="lg"
                    style={{
                      backgroundColor: "#CDF59B",
                      borderRadius: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                  <PieChart
                    style={{ width: "50%", height: "80%", marginLeft: "25%", paddingTop: "1em" }}
                    center={[20, 20]}
                    viewBoxSize={[40, 40]}
                    radius={20}
                    label={({ dataEntry }) =>
                      dataEntry.title == "Connected" ? dataEntry.value + "%" : ""
                    }
                    labelPosition={0}
                    labelStyle={{
                      ...defaultLabelStyle,
                    }}
                    lineWidth={20}
                    data={[
                        { title: "Connected", value: vehicleConnected?.connected ? Math.round(vehicleConnected?.connected/vehicleConnected?.total*100) : 0, color: "#CDF59B" },
                        { title: "Not Connected", value: vehicleConnected?.connected ? 100 - Math.round(vehicleConnected?.connected/vehicleConnected?.total*100) : 100, color: "#FC9797" }
                    ]}
                  />
                  <br />
                  {selectedVehicle && Object.keys(selectedVehicle).length !== 0 && (
                    <SoftTypography
                      variant="caption"
                      fontWeight="regular"
                      style={{ marginLeft: "20%" }}
                    >
                      Last Updated{" "}
                      {(() => {
                        const lastUpdatedStr =
                          vehicleMap[selectedVehicle.id].status["Last updated"];
                        const dateObject = new Date(lastUpdatedStr * 1000);
                        const now = new Date();
                        const diffMs = now - dateObject;
                        const diffMins = Math.floor(diffMs / 1000 / 60);
                        return diffMins + " minutes ago";
                      })()}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} xl={3} style={{ display: "flex", alignItems: "stretch" }}>
              <Card>
                <SoftBox p={3}>
                  <SoftBadge
                    variant="standard"
                    color="success"
                    badgeContent={"Vehicles Ready For Use"}
                    container
                    size="lg"
                    style={{
                      backgroundColor: "#CDF59B",
                      borderRadius: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                  <PieChart
                    style={{ width: "50%", height: "80%", marginLeft: "25%", paddingTop: "1em" }}
                    center={[20, 20]}
                    viewBoxSize={[40, 40]}
                    radius={20}
                    label={({ dataEntry }) =>
                      dataEntry.title == "Ready" ? dataEntry.value + "%" : ""
                    }
                    labelPosition={0}
                    labelStyle={{
                      ...defaultLabelStyle,
                    }}
                    lineWidth={20}
                    data={[
                        { title: "Ready", value: vehicleConnected?.ready ? Math.round(vehicleConnected?.ready/vehicleConnected?.total*100) : 0, color: "#CDF59B" },
                        { title: "Not Ready", value: vehicleConnected?.ready ? 100 - Math.round(vehicleConnected?.ready/vehicleConnected?.total*100) : 100, color: "#FC9797" },
                    ]}
                  />
                  <br />
                  <SoftTypography
                    variant="caption"
                    fontWeight="regular"
                    style={{ marginLeft: "15%" }}
                  >
                    Based on user specified threshold
                  </SoftTypography>
                </SoftBox>
                <br />
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
      {/* The reason for the selected Vehicle being there with the variable is to ensure that if a company doesn't have any vehichles
      the optefy page does not error out */}
      {selectedVehicle && (
        <Card style={{ padding: "30px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <SoftTypography variant="h3" style={{ marginRight: "20px" }}>
              Real Time Status:
            </SoftTypography>
            <SoftSelect
              placeholder="Select a vehicle"
              options={vehicles}
              value={selectedVehicle}
              onChange={(option) => setSelectedVehicle(option)}
            />
            {Object.keys(selectedVehicle).length !== 0 && (
              <SoftTypography variant="body2" style={{ marginLeft: "20px" }}>
                Last Updated{" "}
                {makeDateReadable(vehicleMap[selectedVehicle.id].status["Last updated"], true)}
              </SoftTypography>
                      )}           
          </div>
             
          {selectedVehicle ? (
            <div style={{ display: "flex", marginTop: "30px" }}>
                {VehicleImage(selectedVehicle)}
              <Grid container spacing={3} style={{ flex: "3" }}>
                {Object.keys(selectedVehicle).length !== 0 &&
                  (vehicleMap[selectedVehicle.id].status.Ignition ? (
                    <Grid item xs={4} sm={4} xl={4}>
                      <MiniStatisticsCard
                        bgColor="success"
                        title={{ text: "ignition", fontWeight: "regular" }}
                        count="On"
                        icon={{ component: "key" }}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={4} sm={4} xl={4}>
                      <MiniStatisticsCard
                        bgColor="dark"
                        title={{ text: "ignition", fontWeight: "regular" }}
                        count="Off"
                        icon={{ component: "key" }}
                      />
                    </Grid>
                  ))}
                {Object.keys(selectedVehicle).length !== 0 &&
                  (vehicleMap[selectedVehicle.id].status.Plugged ? (
                    <Grid item xs={4} sm={4} xl={4}>
                      <MiniStatisticsCard
                        bgColor="success"
                        title={{ text: "plugged in", fontWeight: "regular" }}
                        count="On"
                        icon={{ component: "power" }}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={4} sm={4} xl={4}>
                      <MiniStatisticsCard
                        bgColor="dark"
                        title={{ text: "plugged in", fontWeight: "regular" }}
                        count="Off"
                        icon={{ component: "power" }}
                      />
                    </Grid>
                  ))}
                {Object.keys(selectedVehicle).length !== 0 &&
                  (vehicleMap[selectedVehicle.id].status.Charging ? (
                    <Grid item xs={4} sm={4} xl={4}>
                      <MiniStatisticsCard
                        bgColor="success"
                        title={{ text: "charging", fontWeight: "regular" }}
                        count="On"
                        icon={{ component: "battery_charging_full" }}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={4} sm={4} xl={4}>
                      <MiniStatisticsCard
                        bgColor="dark"
                        title={{ text: "charging", fontWeight: "regular" }}
                        count="Off"
                        icon={{ component: "battery_charging_full" }}
                      />
                    </Grid>
                  ))}
                {Object.keys(selectedVehicle).length !== 0 && (
                  <Grid item xs={4} sm={4} xl={4}>
                    <MiniStatisticsCard
                      bgColor="secondary"
                      title={{ text: "battery", fontWeight: "regular" }}
                      count={vehicleMap[selectedVehicle.id].status.Battery + " %"}
                      icon={{ component: "battery_std" }}
                    />
                  </Grid>
                )}
                {Object.keys(selectedVehicle).length !== 0 && (
                  <Grid item xs={4} sm={4} xl={4}>
                    <MiniStatisticsCard
                      bgColor="secondary"
                      title={{ text: "remaining range", fontWeight: "regular" }}
                      count={vehicleMap[selectedVehicle.id].status.Range + " KM"}
                      icon={{ component: "drive_eta" }}
                    />
                  </Grid>
                )}
                {Object.keys(selectedVehicle).length !== 0 && (
                  <Grid item xs={4} sm={4} xl={4}>
                    <MiniStatisticsCard
                      bgColor="secondary"
                      title={{ text: "odometer", fontWeight: "regular" }}
                      count={vehicleMap[selectedVehicle.id].status.Odometer + " KM"}
                      icon={{ component: "speed" }}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          ) : (
            <div></div>
          )}
        </Card>
      )}
      <Card></Card>
      <SoftBox mb={3} style={{ marginTop: "40px", minHeight: "200px" }}>
        <Card style={{ padding: "30px" }}>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
            <SoftTypography variant="h4" style={{ marginRight: "20px" }}>
              Current Status
            </SoftTypography>
            <SoftSelect
              placeholder="Select a vehicle"
              options={vehicleOptions}
              value={vehicleLog}
              onChange={(option) => {
                setVehicleLog(option);
                if (option.value === "All") {
                  setVehicleStatusDisplayedRows(vehicleStatusRows);
                } else {
                  setVehicleStatusDisplayedRows(
                    vehicleStatusRows.filter((e) => e.vin === option.vin)
                  );
                }
              }}
            />
          </div>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={vehicleStatusColumns} rows={vehicleStatusDisplayedRows} />
          </SoftBox>
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default OptefyDemo;
