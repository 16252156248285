import Kia from "assets/images/logos/kia.png";
import Tesla from "assets/images/logos/tesla.png";
import FordLogo from "assets/images/logos/ford.png";
import Cadillac from "assets/images/logos/cadillac.png";
import GMC from "assets/images/logos/gmc.png";
import LionElectric from "assets/images/logos/lion_electric.png";

export function brandToLogo(brand) {
  switch (brand) {
    case "Kia":
      return Kia;
    case "Tesla":
      return Tesla;
    case "Ford":
      return FordLogo;
    case "Cadillac":
      return Cadillac;
    case "GMC":
      return GMC;
    case "Lion Electric":
        return LionElectric;
    default:
      return "";
  }
}