/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Soft UI Dashboard React components
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftButton from "components/SoftButton";
import { brandToLogo } from "layouts/Logo";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import GradientLineChart from "./components/GradientLineChart";
import PieChart from "./components/PieChart";
import SpaceShip from "examples/Icons/SpaceShip";
import SoftProgress from "components/SoftProgress";
import { FiArrowUp } from "react-icons/fi";
import React from "react";
import Grid from "@mui/material/Grid";
import { BACKEND_URL } from "configs";
import { chargingStatusTableColumns } from "./data/chargingTable";
import ProgressBar2 from "./data/ProgressBar2";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import Table from "examples/Tables/Table";

function Author(image, name, email) {
  return (
    <SoftBox display="flex" alignItems="left" pl={9} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

function getChargingStatus(status) {
  if (status.Charging) {
    return (
      <SoftBadge
        style={{ zIndex: 0, position: "relative" }}
        variant="contained"
        color="success"
        badgeContent={<span style={{ minWidth: "72.9px" }}>CHARGING</span>}
        container
      />
    );
  } else if (status.Plugged) {
    return (
      <SoftBadge
        style={{ zIndex: 0, position: "relative" }}
        variant="contained"
        color="blue"
        badgeContent={<span style={{ minWidth: "72.9px" }}>IN QUEUE</span>}
        container
      />
    );
  } else {
    return (
      <SoftBadge
        style={{ zIndex: 0, position: "relative" }}
        variant="contained"
        color="error"
        badgeContent={"unplugged"}
        container
      />
    );
  }
}

function getPriority(make) {
  // in the future add a column for priority in Vehicle table (in the backend)
  let priority;
  switch (make) {
    case "Kia":
      priority = 3;
      break;
    case "Tesla":
      priority = 1;
      break;
    case "Ford":
      priority = 2;
      break;
    case  "Cadillac":
      priority = 1;
      break;
    case  "GMC":
      priority = 1;
      break;
    case  "Lion Electric":
      priority = 1;
  }
  return (
    <SoftTypography style={{ textAlign: "center" }} variant="caption">
      {priority}
    </SoftTypography>
  );
}

const iconStyle = {
  fontSize: "22px",
  color: "#82d616",
  verticalAlign: "middle",
};

function LoadManagementPage() {
  // slider
  const Input = styled(MuiInput)`
    width: 25px;
  `;
  const [value, setValue] = React.useState(100); // in future get this value from backend
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };
  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [chargingStatusTableRows, setChargingStatusTableRows] = React.useState([]); // contains all rows
  const makeToPercentage = {
    // in the future add a column for percentageOfCap in Vehicle table (in the backend)
    Kia: 15,
    Tesla: 50,
    Ford: 30,
  };

  React.useEffect(() => {
    async function fetchData() {
      const access_token = localStorage.getItem("access_token");
      let res = await fetch(BACKEND_URL + "/vehicles/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + access_token,
        },
      });
      if (res.status == 200) {
        let response_json = await res.json();
        let vehicle_status_rows = response_json.map((e) => {
          let logoImage = brandToLogo(e.make);
          return {
            vin: e.vin,
            alias: (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {e.alias}
              </SoftTypography>
            ),
            priority: getPriority(e.make),
            vehicle: Author(logoImage, e.make, e.alias),
            mode: getChargingStatus(e.status),
            "% of cap": <ProgressBar2 value={makeToPercentage[e.make]} />,
          };
        });
        setChargingStatusTableRows(vehicle_status_rows);
      }
    }
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={1} style={{ display: "flex", alignItems: "stretch" }}>
            <Grid item xs={12} sm={12} xl={8.5}>
              <Card>
                <SoftTypography
                  variant="body3"
                  color="dark"
                  fontWeight="bold"
                  style={{ margin: "20px 20px" }}
                >
                  Energy Load
                </SoftTypography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <FiArrowUp style={{ ...iconStyle, marginLeft: "20px" }} />
                  <SoftTypography
                    variant="body2"
                    color="text"
                    fontWeight="bold"
                    style={{ marginBottom: "20px", marginLeft: "2px" }}
                  >
                    4% more
                  </SoftTypography>
                  <SoftTypography
                    variant="body2"
                    color="dark"
                    fontWeight="bold"
                    style={{ marginBottom: "20px", marginLeft: "3px" }}
                  >
                    in April 2023
                  </SoftTypography>
                </div>
                <GradientLineChart
                  chart={{
                    labels: ["00", "03", "06", "09", "12", "15", "18", "21", "24"],
                    datasets: [
                      {
                        label: "Load",
                        color: "info",
                        data: [10, 80, 60, 44, 100, 50, 80, 46, 100],
                      },
                    ],
                  }}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} xl={3.5} style={{ display: "flex", alignItems: "stretch" }}>
              <Card>
                <SoftBox p={3}>
                  <SoftTypography
                    variant="h5"
                    style={{
                      borderRadius: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Current Summary
                  </SoftTypography>
                  <Grid
                    container
                    spacing={2}
                    style={{ display: "flex", alignItems: "stretch", justifyContent: "center" }}
                  >
                    <Grid item xs={6} sm={6} xl={12}>
                      <PieChart
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        height={120}
                        chart={{
                          labels: ["Charging", "In Queue", "Buffer", "Curtailed"],
                          datasets: {
                            label: "Status",
                            backgroundColors: ["blue", "green", "red", "yellow"],
                            data: [30, 44, 19, 7],
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} xl={6}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <SoftBox
                          bgColor="error"
                          variant="gradient"
                          borderRadius="sm"
                          shadow="lg"
                          opacity={1}
                          px={1}
                          py={0.5}
                          style={{ display: "inline-flex", marginRight: "10px" }}
                        >
                          <SpaceShip size="10px" color="white" />
                        </SoftBox>
                        <SoftTypography variant="body2">Buffer</SoftTypography>
                      </div>
                      <SoftTypography
                        variant="body2"
                        color="dark"
                        fontWeight="bold"
                        style={{ marginBottom: "2px" }}
                      >
                        19%
                      </SoftTypography>
                      <SoftProgress
                        color="red"
                        variant="gradient"
                        value="19"
                        style={{ margin: "2px 0px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} xl={6}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <SoftBox
                          bgColor="success"
                          variant="gradient"
                          borderRadius="sm"
                          shadow="lg"
                          opacity={1}
                          px={1}
                          py={0.5}
                          style={{ display: "inline-flex", marginRight: "10px" }}
                        >
                          <SpaceShip size="10px" color="white" />
                        </SoftBox>
                        <SoftTypography variant="body2">In Queue</SoftTypography>
                      </div>
                      <SoftTypography
                        variant="body2"
                        color="dark"
                        fontWeight="bold"
                        style={{ marginBottom: "2px" }}
                      >
                        44%
                      </SoftTypography>
                      <SoftProgress
                        color="green"
                        variant="gradient"
                        value="44"
                        style={{ margin: "2px 0px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} xl={6}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <SoftBox
                          bgColor="info"
                          variant="gradient"
                          borderRadius="sm"
                          shadow="lg"
                          opacity={1}
                          px={1}
                          py={0.5}
                          style={{ display: "inline-flex", marginRight: "10px" }}
                        >
                          <SpaceShip size="10px" color="white" />
                        </SoftBox>
                        <SoftTypography variant="body2">Charging</SoftTypography>
                      </div>
                      <SoftTypography
                        variant="body2"
                        color="dark"
                        fontWeight="bold"
                        style={{ marginBottom: "2px" }}
                      >
                        30%
                      </SoftTypography>
                      <SoftProgress
                        color="blue"
                        variant="gradient"
                        value="30"
                        style={{ margin: "2px 0px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} xl={6}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          paddingRight: "10px",
                        }}
                      >
                        <SoftBox
                          bgColor="warning"
                          variant="gradient"
                          borderRadius="sm"
                          shadow="lg"
                          opacity={1}
                          px={1}
                          py={0.5}
                          style={{ display: "inline-flex", marginRight: "10px" }}
                        >
                          <SpaceShip size="10px" color="white" />
                        </SoftBox>
                        <SoftTypography variant="body2">Curtailed</SoftTypography>
                      </div>
                      <SoftTypography
                        variant="body2"
                        color="dark"
                        fontWeight="bold"
                        style={{ marginBottom: "2px" }}
                      >
                        7%
                      </SoftTypography>
                      <SoftProgress
                        color="yellow"
                        variant="gradient"
                        value="7"
                        style={{ margin: "2px 0px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} xl={12}>
                      <SoftTypography
                        variant="body3"
                        color="dark"
                        fontWeight="bold"
                        style={{ marginLeft: "10px" }}
                      >
                        Activity
                      </SoftTypography>
                      <SoftTypography
                        variant="body2"
                        color="text"
                        fontWeight="bold"
                        style={{ marginTop: "10px" }}
                      >
                        (+23%) than last week
                      </SoftTypography>
                    </Grid>
                  </Grid>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
      <SoftBox mb={3} style={{ marginTop: "0px", minHeight: "200px" }}>
        <Card style={{ padding: "30px" }}>
          <Grid
            container
            style={{
              display: "flex",
              alignItems: "stretch",
              marginBottom: "20px",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              <div style={{ display: "flex", alignItems: "center" }}>
                <SoftTypography variant="h4" style={{ marginLeft: "20px", marginRight: "20px" }}>
                  Current Status
                </SoftTypography>
              </div>
            </Grid>
            <Grid item>
              <div>
                <IconButton
                  aria-describedby={id}
                  variant="contained"
                  onClick={handleClick}
                  style={{ display: "flex", marginBottom: 5 }}
                >
                  <MoreVertIcon></MoreVertIcon>
                </IconButton>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  PaperProps={{
                    style: { backgroundColor: "white", opacity: 1 },
                  }}
                >
                  <SoftTypography variant="h6" style={{ marginRight: "10px", marginTop: "5px" }}>
                    Set the max limit:
                  </SoftTypography>

                  <Box sx={{ width: "300px" }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs>
                        <Slider
                          value={typeof value === "number" ? value : 0}
                          style={{ marginTop: "5px" }}
                          onChange={handleSliderChange}
                          aria-labelledby="input-slider"
                        />
                      </Grid>
                      <Grid item>
                        <Input
                          value={value}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          inputProps={{
                            step: 10,
                            min: 0,
                            max: 100,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </Grid>

                      <SoftButton
                        color="info"
                        variant="gradient"
                        size="small"
                        sx={{ width: "50px", marginLeft: "10px", marginTop: "15px" }}
                        onClick={handleClose}
                      >
                        Save
                      </SoftButton>
                    </Grid>
                  </Box>
                </Popover>
              </div>
            </Grid>
          </Grid>

          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={chargingStatusTableColumns} rows={chargingStatusTableRows} />
          </SoftBox>
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default LoadManagementPage;
